import React from "react";
import clsx from "clsx";
import { Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { FiSquare, FiCheckSquare, FiXSquare } from "react-icons/fi";
import { GoSquare } from "react-icons/go";
import { GrLocation } from "react-icons/gr";
import { BsWhatsapp } from "react-icons/bs";
import { BsTelephone } from "react-icons/bs";
const PREFIX = "SHIPMENT_PRINT";

export const classes = {
  hideSenderPhone: `${PREFIX}-hideSenderPhone`,
  shipmentInvoiceContainer: `${PREFIX}-shipmentInvoiceContainer`,
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  stickyRecipientName: `${PREFIX}-stickyRecipientName`,
  stickyDestination: `${PREFIX}-stickyDestination`,
  stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
  receipt: `${PREFIX}-receipt`,
  receiptSmall: `${PREFIX}-receiptSmall`,
  leftRadius: `${PREFIX}-leftRadius`,
  rightRadius: `${PREFIX}-rightRadius`,
  body: `${PREFIX}-body`,
  section: `${PREFIX}-section`,
  header: `${PREFIX}-header`,
  reportHeader: `${PREFIX}-reportHeader`,
  tableCell: `${PREFIX}-tableCell`,
  centerContent: `${PREFIX}-centerContent`,
  barcode: `${PREFIX}-barcode`,
  tableHeader: `${PREFIX}-tableHeader`,
  logo: `${PREFIX}-logo`,
  dFlex: `${PREFIX}-dFlex`,
  tableBorder: `${PREFIX}-tableBorder`,
  marginRight1: `${PREFIX}-marginRight1`,
  width21: `${PREFIX}-width21`,
  width28: `${PREFIX}-width28`,
  width30: `${PREFIX}-width30`,
  width50: `${PREFIX}-width50`,
  width72: `${PREFIX}-width72`,
  width79: `${PREFIX}-width79`,
  width90: `${PREFIX}-width90`,
  width100: `${PREFIX}-width100`,
  height9: `${PREFIX}-height9`,
  height_3_13: `${PREFIX}-height_3_13`,
  height35: `${PREFIX}-height35`,
  height65: `${PREFIX}-height65`,
  borderRadius: `${PREFIX}-borderRadius`,
  borderRight: `${PREFIX}-borderRight`,
  headerStyle: `${PREFIX}-headerStyle`,
  logoImg: `${PREFIX}-logoImg`,
  flexChild: `${PREFIX}-flexChild`,
  container: `${PREFIX}-container`,
  topHeaders: `${PREFIX}-topHeaders`,
  details: `${PREFIX}-details`,
  notes: `${PREFIX}-notes`,
  borderBottom: `${PREFIX}-borderBottom`,
  description: `${PREFIX}-description`,
  footer: `${PREFIX}-footer`,
  reportFooter: `${PREFIX}-reportFooter`,
  reportHeaderTitle: `${PREFIX}-reportHeaderTitle`,
  reportHeaderMiddleTop: `${PREFIX}-reportHeaderMiddleTop`,
  reportHeaderMiddleBottom: `${PREFIX}-reportHeaderMiddleBottom`,
  alignItemsCenter: `${PREFIX}-alignItemsCenter`,
  instructions: `${PREFIX}-instructions`,
  tableCellKey: `${PREFIX}-tableCellKey`,
  footerDesc: `${PREFIX}-footerDesc`,
  margin4: `${PREFIX}-margin4`,
  waybillNoteThat: `${PREFIX}-waybillNoteThat`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.hideSenderPhone}`]: {
    display: "none !important",
  },


  [`& .${classes.header}`]: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    height: "15%",
    justifyContent: "space-between",
    // borderBottom: "1px solid #000",
    paddingBottom: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },

  [`& .${classes.reportHeaderTitle}`]: {
    margin: 0,
    fontSize: "18px",
    fontWeight: 800,
    lineHeight: "unset",
    textAlign: "end"
  },
  [`& .${classes.reportHeaderMiddleTop}`]: {
    margin: 0,
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "unset"
  },
  [`& .${classes.reportHeaderMiddleBottom}`]: {
    margin: 0,
    marginTop: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "unset"
  },

  [`& .${classes.body}`]: {
    padding: theme.spacing(0.5),
    height: "80%",
    display: "flex",
    lineHeight: 1.1,
    border: "1px solid #000",
  },
  [`& .${classes.bodyThreeCopies}`]: {
    padding: "8px 0px",
    height: "85%",
    display: "flex",
    lineHeight: 1.1,
  },


  [`& .${classes.tableCell}`]: {
    display: "flex",
    padding: '0 6px',
    // border: "1px solid black",
    // borderBlockEnd: "none",
    // overflow: "hidden",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    fontSize: "15px"
  },

  [`& .${classes.dFlex}`]: {
    display: "flex",
  },

  [`& .${classes.alignItemsCenter}`]: {
    alignItems: "center",
  },

  [`& .${classes.instructions}`]: {
    backgroundColor: "#d1d2d4",
    padding: theme.spacing(1)
  },

  [`& .${classes.tableCellKey}`]: {
    margin: 0,
    fontSize: "15px",
    fontWeight: 800,
  },

  [`& .${classes.reportFooter}`]: {
    display: "flex",
    width: "100%",
    direction: "rtl",
    justifyContent: "space-between"
  },

  [`& .${classes.footer}`]: {
    overflow: "hidden",
    height: "7%",
    paddingTop: theme.spacing(0.5),
    // borderTop: "1px solid #000",
  },
  [`& .${classes.waybillNoteThat}`]: {
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.footerDesc}`]: {
    lineHeight: "unset",
    margin: "0px",
    display: "flex",
    fontWeight: "900",
    [`& span`]: {
      display: "flex",
      alignItems: "center",
      fontWeight: "600",
      fontSize: "10px",
      marginLeft: theme.spacing(0.5)
    },
    // direction: "rtl"
  },

  [`& .${classes.shipmentInvoiceContainer}`]: {
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
    height: "272mm",
    fontSize: "75%",
    color: "black",
    "&:first-of-type": {
      "& $receipt": {
        "& :first-of-type": {
          borderTop: "none",
          // backgroundColor:"red"
        },
      },
    },
    WebkitPrintColorAdjust: "exact !important",
    // -webkit-print-color-adjust: exact !important,
  },

  [`& .${classes.printSticky}`]: {
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
    maxWidth: "5cm",
    maxHeight: "4cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
  },

  [`& .${classes.stickyCode}`]: {
    height: "calc(100% * (1/4))",
    overflow: "hidden",
    fontSize: "30px !important",
  },

  [`& .${classes.stickyRecipientName}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyDestination}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyRecipientAddress}`]: {
    height: "calc(100% * (1.5/4))",
    overflow: "hidden",
  },

  [`& .${classes.container}`]: {
    flexDirection: "column",
    gap: 8,
  },
  [`& .${classes.details}`]: {
    flexDirection: "column",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.notes}`]: {
    flex: "1 1 auto",
    minHeight: "calc(100% / 21 * 5)",
  },
  [`& .${classes.description}`]: {
    flex: "1 1 auto",
    minHeight: "calc(100% / 21 * 9)",
  },

  [`& .${classes.flexChild}`]: {
    display: "flex",
    flex: "1 1 auto",
    minHeight: 0,
  },

  [`& .${classes.borderBottom}`]: {
    borderBottom: "1px solid black !important",
  },

  [`& .${classes.topHeaders}`]: {
    flex: "1 1 auto",
    minHeight: "calc(100% / 21 * 2.5)",
  },

  [`& .${classes.receipt}`]: {
    fontSize: 12,
    height: "50%",
    padding: "8mm",
    boxSizing: "border-box",
    textAlign: "start",
    borderTop: "1px dashed black",
    "&:first-of-type": {
      "@media print": {
        borderTop: "none",
      },
    },
    "@media print": {
      padding: "5px 0px !important",
    },
  },

  [`& .${classes.receiptSmall}`]: {
    height: "calc(100%/3)",
    padding: "6mm",
  },

  [`& .${classes.leftRadius}`]: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },

  [`& .${classes.rightRadius}`]: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },

  [`& .${classes.section}`]: {
    height: "100%",
  },

  [`& .${classes.centerContent}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: "13px",
    top: 0
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 48,
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: "#ddd",
  },

  [`& .${classes.logo}`]: {
    height: "100%",
  },

  [`& .${classes.tableBorder}`]: {
    height: "inherit",
  },

  [`& .${classes.marginRight1}`]: {
    marginRight: "1%",
  },

  [`& .${classes.width21}`]: {
    width: "21%",
  },
  [`& .${classes.width28}`]: {
    width: "28%",
  },

  [`& .${classes.width30}`]: {
    width: "30%",
  },

  [`& .${classes.width50}`]: {
    width: "50%",
  },

  [`& .${classes.width72}`]: {
    width: "72%",
  },

  [`& .${classes.width79}`]: {
    width: "79%",
  },

  [`& .${classes.width90}`]: {
    width: "90%",
  },

  [`& .${classes.width100}`]: {
    width: "100%",
  },

  [`& .${classes.height9}`]: {
    height: "calc(100% / 21 * 9)",
  },

  [`& .${classes.height_3_13}`]: {
    height: "calc(100% * 3 / 13)",
  },

  [`& .${classes.height35}`]: {
    height: "35%",
  },

  [`& .${classes.height65}`]: {
    height: "65%",
  },

  [`& .${classes.borderRadius}`]: {
    borderRadius: 10,
    border: "1px solid black",
  },

  [`& .${classes.borderRight}`]: {
    borderRight: "1px solid black",
    textTransform: 'capitalize',
  },

  [`& .${classes.headerStyle}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.logoImg}`]: {
    maxWidth: "100%",
    maxHeight: "48px",
  },


}));

export function CheckCrossIcons(props) {
  return props.active ? (
    <FiCheckSquare size={20} />
  ) : (
    props.isBatch ? <FiSquare /> : <FiXSquare size={20} />
  );
}


export default function WaybillA4(props) {
  const { t } = useTranslation();

  const {
    shipment,
    shipmentIndex,
    threeCopies,
    threeDifferent,
    logoImg,
    rtsShipment,
    currency,
    isBatch,
  } = props;
  return (
    <div
      key={shipmentIndex}
      className={clsx(classes.receipt, {
        [classes.receiptSmall]: threeCopies || threeDifferent,
      })}
    >
      <div className={classes.header}>
        <div>
          <p className={classes.reportHeaderTitle} style={{ direction: "rtl" }}>
            Alexander Express <br /> Courier Service
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <p className={classes.reportHeaderMiddleTop}>
            اليكسندر للشحن البري السريع
          </p>
          <p className={classes.reportHeaderMiddleBottom}>
            #بيعها_أونلاين_وسيب_الشحن_علينا
          </p>
        </div>
        <div>{logoImg}</div>

      </div>
      <div className={clsx(classes.body, {
        [classes.bodyThreeCopies]: threeCopies || threeDifferent,
      })}>
        <Grid container width={"100%"} justifyContent={"space-evenly"} alignItems={"center"} flexDirection={"column"} wrap="nowrap" position={"relative"}>
          <div className={clsx(classes.barcode, classes.centerContent)}>
            *{shipment?.code}*
          </div>
          <Grid xs={12}>
            <div className={clsx(
              classes.dFlex, classes.flexChild,
              {
                [classes.margin4]: threeCopies || threeDifferent,
              }
            )}
              style={{ justifyContent: "space-between" }}
            >
              <div className={clsx(
                classes.dFlex,
              )}>
                <div
                  className={clsx(
                    classes.dFlex,
                    classes.alignItemsCenter
                  )}
                >
                  <div
                    style={{ alignItems: "end" }}
                    className={clsx(classes.tableCell)}
                  >
                    <CheckCrossIcons
                      active={shipment?.openable?.code === "Y"}
                      isBatch={isBatch}
                    />
                  </div>
                  <div
                    style={{ fontSize: 14 }}
                    className={clsx(classes.tableCell)}
                  >
                    {t("packageOpen")}
                  </div>
                </div>
                <div
                  className={clsx(
                    classes.dFlex,
                    classes.alignItemsCenter
                  )}
                >
                  <div
                    style={{ alignItems: "end" }}
                    className={clsx(classes.tableCell)}
                  >
                    <FiSquare />
                  </div>
                  <div
                    style={{ fontSize: 14 }}
                    className={clsx(classes.tableCell)}
                  >
                    {t("checkingPackage")}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexName")} :
              </div>
              <div className={clsx(classes.tableCell)}>
                {shipment?.recipientName}
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexPhone")} :
              </div>
              <div className={clsx(classes.tableCell)}>
                {shipment?.recipientMobile && shipment?.recipientPhone
                  ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                  : shipment?.recipientMobile ?? shipment?.recipientPhone}
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexAddress")} :
              </div>
              <div className={clsx(classes.tableCell)}>
                {shipment?.recipientAddress}
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexCity")} :
              </div>
              <div className={clsx(classes.tableCell)}>
                {shipment?.recipientZone
                  ? shipment?.recipientZone?.name +
                  " - " +
                  shipment?.recipientSubzone?.name
                  : ""}
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexPackageContent")} :
              </div>
              <div className={clsx(classes.tableCell)}>
                {shipment?.description}
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t('alexCollection')} :
              </div>
              <div className={clsx(classes.tableCell)}>
                <Box
                  component="span"
                  sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                >
                  {rtsShipment ? 0 : shipment?.totalAmount}
                </Box>
                <Box component="span" pl="2px">
                  {!isBatch && currency}
                </Box>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexSenderName")} :
              </div>
              <div className={clsx(classes.tableCell, classes.dFlex)}>
                {!isBatch &&
                  shipment?.customer?.code + "-" + shipment?.senderName}
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={clsx(classes.flexChild, {
              [classes.margin4]: threeCopies || threeDifferent,
            })}>
              <div
                className={clsx(
                  classes.tableCell,
                  classes.tableCellKey
                )}
              >
                {t("alexNotes")} :
              </div>
              <div className={clsx(classes.tableCell)}>
                {shipment?.notes}
              </div>
            </div>
          </Grid>
          <Grid xs={11}>
            <div className={clsx(classes.instructions)}>
              <div className={clsx(classes.tableCellKey, classes.waybillNoteThat)}>{t('waybillNoteThat')}</div>
              <div style={{ paddingTop: "5px", display: "flex", alignItems: "center" }}>
                <GoSquare size={14} />
                {t('waybillNoteThatOne')}
              </div>
              <div style={{ paddingTop: "5px", display: "flex", alignItems: "center" }}>
                <GoSquare size={14} />
                {t('waybillNoteThatTwo')}
              </div>
              <div style={{ paddingTop: "5px", display: "flex", alignItems: "center" }}>
                <GoSquare size={14} />
                {t('waybillNoteThatThree')}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {!(threeCopies || threeDifferent) && <div className={classes.footer} style={{ direction: "rtl" }}>
        <div className={classes.reportFooter} style={{ direction: "ltr" }}>
          <div>
            <p style={{ direction: "rtl" }} className={classes.footerDesc}>للمتابعة:<span> 01005409245<BsTelephone style={{ margin: "2px" }} />-01012939498<BsTelephone style={{ margin: "2px" }} /></span></p>
          </div>
          <div>
            <p style={{ direction: "rtl" }} className={classes.footerDesc}>العنوان:<span><GrLocation style={{ margin: "2px" }} />فريد السباعي الاريزونا هرم</span></p>
          </div>
          <div>
            <p style={{ direction: "rtl" }} className={classes.footerDesc}>للاستفسار والشكاوي:<span> 01150900059<BsWhatsapp style={{ margin: "2px" }} />-01032400036<BsTelephone style={{ margin: "2px" }} /></span></p>
          </div>
        </div>
      </div>
      }
    </div>
  );
}
